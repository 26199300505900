
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Author",
        "possibleTypes": [
          {
            "name": "User"
          },
          {
            "name": "Driver"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ChatParticipantUnion",
        "possibleTypes": [
          {
            "name": "Driver"
          },
          {
            "name": "Contact"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ContactsAndFarmAffiliatesAndCompanies",
        "possibleTypes": [
          {
            "name": "Contact"
          },
          {
            "name": "Company"
          },
          {
            "name": "MoovsNetworkOperator"
          },
          {
            "name": "GriddnetOperator"
          },
          {
            "name": "MoovsAiOperator"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FarmAffiliate",
        "possibleTypes": [
          {
            "name": "ExternalOperator"
          },
          {
            "name": "MoovsNetworkOperator"
          },
          {
            "name": "GriddnetOperator"
          },
          {
            "name": "MoovsAiOperator"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MetadataUnion",
        "possibleTypes": [
          {
            "name": "TripChangeMetadata"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MoovsTransaction",
        "possibleTypes": [
          {
            "name": "Payment"
          },
          {
            "name": "PaymentRefund"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Node",
        "possibleTypes": [
          {
            "name": "Billing"
          },
          {
            "name": "CancellationPolicy"
          },
          {
            "name": "Comment"
          },
          {
            "name": "Company"
          },
          {
            "name": "Contact"
          },
          {
            "name": "Driver"
          },
          {
            "name": "DriverAvailability"
          },
          {
            "name": "DynamicPricingRule"
          },
          {
            "name": "EmailLog"
          },
          {
            "name": "ExternalOperator"
          },
          {
            "name": "GriddnetOperator"
          },
          {
            "name": "InsurancePolicy"
          },
          {
            "name": "Invoice"
          },
          {
            "name": "MoovsAiOperator"
          },
          {
            "name": "MoovsNetworkOperator"
          },
          {
            "name": "Operator"
          },
          {
            "name": "OperatorRoute"
          },
          {
            "name": "Payment"
          },
          {
            "name": "PaymentMethod"
          },
          {
            "name": "PaymentRefund"
          },
          {
            "name": "PriceZone"
          },
          {
            "name": "PriceZoneRoute"
          },
          {
            "name": "PriceZoneRouteVehicle"
          },
          {
            "name": "Request"
          },
          {
            "name": "Route"
          },
          {
            "name": "ShuttleRoute"
          },
          {
            "name": "Stop"
          },
          {
            "name": "SubPayment"
          },
          {
            "name": "SubRefund"
          },
          {
            "name": "TeamMember"
          },
          {
            "name": "Term"
          },
          {
            "name": "TermType"
          },
          {
            "name": "TrackedFlight"
          },
          {
            "name": "Transaction"
          },
          {
            "name": "Trip"
          },
          {
            "name": "User"
          },
          {
            "name": "UserAccessPermission"
          },
          {
            "name": "Vehicle"
          },
          {
            "name": "VehicleChildSeat"
          },
          {
            "name": "VehicleFeature"
          },
          {
            "name": "VehiclePhoto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PaymentMethodTransaction",
        "possibleTypes": [
          {
            "name": "Payment"
          },
          {
            "name": "PaymentRefund"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchResult",
        "possibleTypes": [
          {
            "name": "Driver"
          },
          {
            "name": "Request"
          },
          {
            "name": "Contact"
          },
          {
            "name": "Company"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ZoneVariantData",
        "possibleTypes": [
          {
            "name": "ZipCodeZone"
          },
          {
            "name": "LocationZone"
          }
        ]
      }
    ]
  }
};
      export default result;
    